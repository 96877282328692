import axios from "axios";
import { auth, fireStoreDB } from "./firebaseConfig";
import * as firebaseAuth from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  setDoc,
  getDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { getProfile, setSecondaryProfile } from "utilities";

export const getDashboardStats = async (type) => {
  const userData = getProfile();
  const querySnapshot = await getDocs(
    query(
      collection(fireStoreDB, type),
      where("userIds", "array-contains-any", [userData.uid])
    )
  );
  const records = querySnapshot.docs.map((doc) => doc.data());
  return records || [];
};
export const getDashboardDocumentStats = async (type) => {
  const userData = getProfile();
  const querySnapshot = await getDocs(
    query(
      collection(fireStoreDB, type),
      where("email", "==", userData.reloadUserInfo.email)
    )
  );
  const records = querySnapshot.docs.map((doc) => doc.data());
  return records || [];
};
export const getAllProfileRecords = async (type) => {
  if (type === "diagnoses") {
    let headersList = {
      "API-Version": "v2",
      "Accept-Language": "en",
      "Content-Type": "application/x-www-form-urlencoded",
    };

    let bodyContent = `q=${
      localStorage.getItem("search-text")
        ? `${localStorage.getItem("search-text")}%`
        : "%a%"
    }`;

    let reqOptions = {
      url: "https://icd11restapi-developer-test.azurewebsites.net/icd/release/11/2024-01/mms/search",
      method: "POST",
      headers: headersList,
      data: bodyContent,
    };

    let response = await axios.request(reqOptions);

    const res = response?.data?.destinationEntities.map((item) => {
      return {
        description: `${item?.theCode} - ${item.title
          .split(`<em class='found'>`)
          .join(" ")
          .split(`<em class='nonwbe'>`)
          .join(" ")
          .split(`</em>`)
          .join(" ")}`,
        userIds: [],
      };
    });
    return res;
  } else {
    if (type != undefined && type != "undefined") {
      const querySnapshot = await getDocs(collection(fireStoreDB, type));
      const response = querySnapshot.docs.map((doc) => doc.data()) || [];
      var newArray = response.filter(
        (value) => Object.keys(value).length !== 0
      );
      var test = newArray.filter((item) => "description" in item);

      return test;
    }

    return [];
  }
  // fetch("https://icd11restapi-developer-test.azurewebsites.net/icd/release/11/2024-01/mms/search", {
  //   "headers": {
  //     "accept": "application/json",
  //     "accept-language": "en",
  //     "api-version": "v2",
  //     "content-type": "multipart/form-data; boundary=----WebKitFormBoundaryW1n9bAtmPZ91JDb7",
  //     "priority": "u=1, i",
  //     "sec-ch-ua": "\"Chromium\";v=\"124\", \"Opera\";v=\"110\", \"Not-A.Brand\";v=\"99\"",
  //     "sec-ch-ua-mobile": "?0",
  //     "sec-ch-ua-platform": "\"Windows\"",
  //     "sec-fetch-dest": "empty",
  //     "sec-fetch-mode": "cors",
  //     "sec-fetch-site": "cross-site",
  //     "Referer": "https://cdpn.io/",
  //     "Referrer-Policy": "strict-origin-when-cross-origin"
  //   },
  //   "body": "------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"q\"\r\n\r\nabs%\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"chapterFilter\"\r\n\r\n10;11;12;13;14;15;16;17;18;19;20;21;22;23;24;25;01;02;03;04;05;06;07;08;09;\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"subtreesFilter\"\r\n\r\n\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"includePostcoordination\"\r\n\r\ntrue\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"useBroaderSynonyms\"\r\n\r\nfalse\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"useFlexiSearch\"\r\n\r\nfalse\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"includeKeywordResult\"\r\n\r\ntrue\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"flatResults\"\r\n\r\ntrue\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"highlightingEnabled\"\r\n\r\ntrue\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7\r\nContent-Disposition: form-data; name=\"medicalCodingMode\"\r\n\r\ntrue\r\n------WebKitFormBoundaryW1n9bAtmPZ91JDb7--\r\n",
  //   "method": "POST"
  // }); ;
};
export const addNewItem = async (type, description) => {
  const userData = getProfile();
  const querySnapshot = await getDocs(
    query(
      collection(fireStoreDB, type),
      where("description", "==", description)
    )
  );

  let docData, docID;
  if (querySnapshot.docs[0]) {
    docData = querySnapshot.docs[0].data();
    docID = querySnapshot.docs[0].id;
  } else {
    const docRef = await addDoc(collection(fireStoreDB, type), {
      description,
      userIds: [],
    });
    docID = docRef.id;
    docData = { description, userIds: [] };
  }

  const documentRef = doc(fireStoreDB, type, docID);
  if (
    !(docData && docData?.userIds && docData?.userIds?.includes(userData.uid))
  ) {
    await updateDoc(documentRef, {
      userIds:
        docData?.userIds && docData?.userIds.length > 0
          ? [...docData?.userIds, userData.uid]
          : [userData.uid],
    });
    return true;
  } else {
    return false;
  }
};
export const addNewDocument = async (requestJson) => {
  try {
    const collectionRef = collection(fireStoreDB, "documents");
    const newDocRef = await addDoc(collectionRef, requestJson);
    return true;
  } catch ({ message }) {
    console.log("Error in adding new Document : ", message);
    return false;
  }
};
export const deleteItem = async (type, description) => {
  const userData = getProfile();
  const querySnapshot = await getDocs(
    query(
      collection(fireStoreDB, type),
      where("description", "==", description)
    )
  );
  const docData = querySnapshot.docs[0].data();
  const docID = querySnapshot.docs[0].id;
  const documentRef = doc(fireStoreDB, type, docID);

  await updateDoc(documentRef, {
    userIds:
      docData?.userIds && docData?.userIds.length > 1
        ? docData?.userIds.filter((item) => item !== userData.uid)
        : [],
  });
  return true;
};
export const getUserDetails = async (liveUID) => {
  const documentRef = doc(fireStoreDB, `user`, liveUID);
  const res = await getDoc(documentRef);
  return res.data();
};
export const saveProfileImage = async (newImageURL, liveUID) => {
  const userData = getProfile();
  const { uid } = userData;
  const documentRef = doc(fireStoreDB, `user`, uid || liveUID);
  await updateDoc(documentRef, {
    avatar: newImageURL,
  });
  return true;
};
export const saveUserProfile = async (requestObject) => {
  const userData = getProfile();
  const { uid } = userData;
  const documentRef = doc(fireStoreDB, `user`, uid);
  await updateDoc(documentRef, requestObject);
  setSecondaryProfile(await getUserDetails(uid));
};
export const savePersonalDetail = async () => {
  const userData = getProfile();
  const { uid } = userData;
  const documentRef = doc(fireStoreDB, `user`, uid);
  const res = await getDoc(documentRef);
  return res.data();
};
export const performLoginThroughFirebase = async ({ email, password }) => {
  try {
    const responseObject = await firebaseAuth.signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return responseObject;
  } catch (error) {
    return error.message;
  }
};
export const performSignUpThroughFirebase = async ({ email, password }) => {
  try {
    const response = await firebaseAuth.createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await verifyEmailAdress(auth);
    return response;
  } catch (error) {
    return error.message;
  }
};
export const performForgetPasswordThroughFirebase = async ({ email }) => {
  try {
    const response = await firebaseAuth.sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    return error.message;
  }
};
export const saveUserData = async (liveUID, userData) => {
  const documentRef = doc(fireStoreDB, "user", liveUID);
  try {
    const response = await setDoc(documentRef, userData);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const updateUserData = async (liveUID, userData) => {
  const documentRef = doc(fireStoreDB, `user`, liveUID);
  await updateDoc(documentRef, {
    cardOrderDate: userData?.cardOrderDate,
    cardOrderStatus: userData?.cardOrderStatus,
  });
  return true;
};

export const updateUserReOrderData = async (liveUID, userData) => {
  const documentRef = doc(fireStoreDB, `user`, liveUID);
  await updateDoc(documentRef, {
    cardReOrderStatus: userData?.cardReOrderStatus,
    cardORerderDate: userData?.cardORerderDate,
  });
  return true;
};

export const checkIfEmailExists = (email) => {
  const response = firebaseAuth
    .fetchSignInMethodsForEmail(auth, email)
    .then(function (signInMethods) {
      if (signInMethods?.length === 0) {
        return true;
      } else {
        return false;
      }
    })
    .catch(function (error) {
      console.error("Error checking email existence: ", error);
    });

  return response;
};

export const deleteUserAndData = async () => {
  const userData = getProfile();
  const liveUID = userData.uid;
  try {
    // Delete user document
    await deleteDoc(doc(fireStoreDB, "user", liveUID));

    const subscriptionsQuerySnapshot = await getDocs(
      query(
        collection(fireStoreDB, "subscriptions"),
        where("user_id", "==", liveUID)
      )
    );
    const deleteSubscriptionsPromises = subscriptionsQuerySnapshot.docs.map(
      (doc) => deleteDoc(doc.ref)
    );
    await Promise.all(deleteSubscriptionsPromises);

    const trackOrderQuerySnapshot = await getDocs(
      query(
        collection(fireStoreDB, "TrackOrder"),
        where("userID", "==", liveUID)
      )
    );
    const deleteTrackOrderPromises = trackOrderQuerySnapshot.docs.map((doc) =>
      deleteDoc(doc.ref)
    );
    await Promise.all(deleteTrackOrderPromises);

    // Delete user's data from 'diagnoses' collection
    // const diagnosesQuerySnapshot = await getDocs(collection(fireStoreDB, "diagnoses"));
    // const deleteDiagnosesPromises = [];
    // diagnosesQuerySnapshot.forEach((doc) => {
    //   const diagnosesData = doc.data();
    //   if (diagnosesData?.userIds && Array.isArray(diagnosesData?.userIds) && diagnosesData?.userIds?.includes(liveUID)) {
    //     deleteDiagnosesPromises.push(deleteDoc(doc.ref));
    //   }
    // });
    // await Promise.all(deleteDiagnosesPromises);

    // Delete user's data from 'allergies' collection
    //  const allergiesQuerySnapshot = await getDocs(collection(fireStoreDB, "allergies"));
    //  const deleteAllergiesPromises = [];
    //  allergiesQuerySnapshot.forEach((doc) => {
    //    const allergiesData = doc.data();
    //    if (allergiesData?.userIds && Array.isArray(allergiesData?.userIds) && allergiesData?.userIds?.includes(liveUID)) {
    //      deleteAllergiesPromises.push(deleteDoc(doc.ref));
    //    }
    //  });
    //  await Promise.all(deleteAllergiesPromises);

    // Finally, delete the user from authentication
    await firebaseAuth.deleteUser(auth.currentUser);

    // Logout user
    await firebaseAuth.signOut(auth);
    return true;
  } catch (error) {
    console.error("Error deleting user and data:", error);
    return false; // Deletion failed
  }
};

export const verifyEmailAdress = async (auth) => {
  try {
    await firebaseAuth.sendEmailVerification(auth.currentUser);
  } catch (error) {
    console.log(error);
  }
};
