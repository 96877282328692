import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import {
  ENUMS,
  GenderList,
  InsuranceTypesList,
  OrganDonarList,
  constants,
  countriesList,
  dateFormat,
  emergencyRelationshipList,
} from "common";
import { GenericModal, ProfileRowSection } from "components";
import { Button } from "react-bootstrap";
import { getSecondaryProfile, clearStorage } from "utilities";
import { saveUserProfile, deleteUserAndData } from "services";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { colors } from "common/theme";
import { ApplicationContext } from "shared";
import { nationality, nationalityOptions } from "common/nationality";
import { useNavigate } from "react-router";
import { routePaths } from "routes";
import { civilStatus, civilStatusOptions } from "common/civilstatus";

const ManageAccount = () => {
  const {
    profileSectionTitle: {
      personalInformation,
      postalAddress,
      insurance,
      emergency,
      deleteUser,
    },
  } = constants;
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [isPersonalValid, setIsPersonalValid] = useState(false);
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [isInsurancelValid, setIsInsurancelValid] = useState(false);
  const [isEmergencyValid, setIsEmergencyValid] = useState(false);
  const [isDataSaveModalOpen, setDataSaveModalOpen] = useState(false);

  const [userData, setUserData] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [insuranceData, setInsuranceData] = useState([]);
  const [emergencyData, setEmergencyData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [apiResponse, setApiResponse] = useState({
    type: ENUMS.MODAL_TYPES.ERROR,
    title: "",
    body: "",
  });
  const [apiDeleteResponse, setApiDeleteResponse] = useState({
    type: ENUMS.MODAL_TYPES.SURE,
    title: "",
    body: "",
  });

  const { fieldTypeEnums } = constants;

  const { showValidationMsg } = useContext(ApplicationContext);
  useEffect(() => {
    const profileData = getSecondaryProfile();

    setUserData(profileData);
    if (isObject(profileData.insurance)) {
      setInsuranceData([profileData.insurance]);
    } else {
      const tempData = [...insuranceData];
      setInsuranceData(tempData.concat(profileData.insurance));
    }
    if (isObject(profileData.emergencyContact)) {
      setEmergencyData([profileData.emergencyContact]);
    } else {
      const tempData = [...emergencyData];
      setEmergencyData(tempData.concat(profileData.emergencyContact));
    }

    setDateOfBirth(profileData.birthDate || "");
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDataSaveModalOpen(true);
    }, 3000);

    return () => clearTimeout(timeout); 
  }, []);

  const updateData = (keyName, value) => {
    const tempData = { ...userData };
    tempData[keyName] = value;
    setUserData(tempData);
  };

  let navigate = useNavigate();

  let isObject = function (a) {
    return !!a && a.constructor === Object;
  };

  let isArray = function (a) {
    return !!a && a.constructor === Array;
  };

  const getTheFormatedDate = (originalString) => {
    let originalDate = moment(originalString);
    if (
      !(
        originalDate == "Invalid date" &&
        originalDate == "" &&
        originalDate == undefined
      )
    )
      originalDate = new moment().format("L");
    setDateOfBirth(`${originalDate}`);
  };

  const savePersonalInformation = async () => {
    setLoading(true);
    const response = await saveUserProfile({
      firstName: userData.firstName,
      lastName: userData.lastName,
      birthDate: dateOfBirth,
      gender: userData.gender,
      ssn: userData.ssn,
      nationality: userData.nationality,
      homephone: userData?.homephone,
      phone: userData.phone,
      organDonation: userData.organDonation,
    });
    setApiResponse({
      type: ENUMS.MODAL_TYPES.SUCCESS,
      title: "Success",
      body: "Your Porsonal Information Details have been updated",
    });
    setShowModal(true);
    setLoading(false);
  };

  const savePostalAddress = async () => {
    setLoading(true);
    const response = await saveUserProfile({
      postalAddress: userData.postalAddress,
    });
    setApiResponse({
      type: ENUMS.MODAL_TYPES.SUCCESS,
      title: "Success",
      body: "Your Address Details have been updated",
    });
    setShowModal(true);
    setLoading(false);
  };

  const saveInsurance = async () => {
    setLoading(true);
    const response = await saveUserProfile({
      insurance: insuranceData,
    });
    setApiResponse({
      type: ENUMS.MODAL_TYPES.SUCCESS,
      title: "Success",
      body: "Your Insurance Details have been updated",
    });
    setShowModal(true);
    setLoading(false);
  };

  const saveEmergencyContact = async () => {
    setLoading(true);
    const response = await saveUserProfile({
      emergencyContact: emergencyData,
    });
    setApiResponse({
      type: ENUMS.MODAL_TYPES.SUCCESS,
      title: "Success",
      body: "Your Emergency Details have been updated",
    });
    setShowModal(true);
    setLoading(false);
  };

  const onDeleteUser = async () => {
    setApiDeleteResponse({
      type: ENUMS.MODAL_TYPES.SURE,
      title: "Account Deletion",
      body: "Are you sure want to delete your account?",
    });
    setShowDeleteModal(true);
  };

  const onConfirmDelete = async () => {
    setLoading(true);
    const response = await deleteUserAndData();
    setShowDeleteModal(false);
    clearStorage();
    navigate({
      pathname: routePaths.signIn,
    });
    setLoading(false);
  };

  return (
    <div className="accountContainer">
      <div className="manage-tabs">
        <span
          className={selectedIndex == 1 ? "selectedTab" : "unSelectedTab"}
          onClick={() => setSelectedIndex(1)}
        >
          {personalInformation}
        </span>
        <span
          className={selectedIndex == 2 ? "selectedTab" : "unSelectedTab"}
          onClick={() => setSelectedIndex(2)}
        >
          {postalAddress}
        </span>
        <span
          className={selectedIndex == 3 ? "selectedTab" : "unSelectedTab"}
          onClick={() => setSelectedIndex(3)}
        >
          {insurance}
        </span>
        <span
          className={selectedIndex == 4 ? "selectedTab" : "unSelectedTab"}
          onClick={() => setSelectedIndex(4)}
        >
          {emergency}
        </span>
        <span
          className={selectedIndex == 5 ? "selectedTab" : "unSelectedTab"}
          onClick={() => onDeleteUser()}
        >
          {deleteUser}
        </span>
      </div>

      <div style={{ flex: 1 }}>
        {selectedIndex == 1 && (
          <div className="sectionContainer">
            <div className="submitButtonWrapper">
              <span className="sectionTitle">{personalInformation}</span>

              <div className="bottomButtonContainer">
                <button
                  disabled={isPersonalValid}
                  className={
                    isPersonalValid ? "disableSubmitButton" : "addItemButton"
                  }
                  onClick={() => savePersonalInformation()}
                >
                  {loading ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    <span>{`SUBMIT`}</span>
                  )}
                </button>
              </div>
            </div>

            <div className="profileSectionDivider" />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              label={`First Name`}
              isRequired={true}
              value={userData.firstName}
              fieldType="input"
              setValue={(value) => updateData("firstName", value)}
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              label={`Last Name`}
              value={userData.lastName}
              isRequired={true}
              fieldType="input"
              setValue={(value) => updateData("lastName", value)}
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              label={`Date of Birth`}
              value={dateOfBirth}
              fieldType="calendar"
              isRequired={true}
              setValue={(value) => {
                setDateOfBirth(value);
              }}
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              label={`Gender`}
              fieldType="select"
              value={userData.gender ? userData.gender : GenderList[0].value}
              isRequired={true}
              options={GenderList}
              setValue={(value) => {
                updateData("gender", value);
              }}
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              label={`National ID Number`}
              value={userData.ssn}
              fieldType="input"
              isRequired={true}
              setValue={(value) => updateData("ssn", value)}
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              label={`Nationality`}
              value={
                userData.nationality
                  ? userData.nationality
                  : nationalityOptions[0].value
              }
              fieldType="select"
              isRequired={true}
              options={nationalityOptions}
              setValue={(value) => updateData("nationality", value)}
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              label={`Civil Status`}
              options={civilStatusOptions}
              value={
                userData.relationShip
                  ? userData.relationShip
                  : civilStatusOptions[0].value
              }
              fieldType="select"
              setValue={(value) => updateData("relationShip", value)}
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              label={`Phone`}
              isRequired={true}
              value={userData.phone}
              fieldType="phone-num"
              isCellular={false}
              setValue={(value) => updateData("phone", value)}
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              isRequired={false}
              label={`Home Phone`}
              value={userData.homephone}
              fieldType="phone-num"
              isCellular={false}
              setValue={(value) => updateData("homephone", value)}
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              isRequired={true}
              label={`Email`}
              value={userData.email}
              fieldType="readOnly"
            />
            <ProfileRowSection
              setIsValid={setIsPersonalValid}
              label={`Are you organ donor?`}
              value={userData.organDonation ? `Yes` : `No`}
              fieldType="select"
              isRequired={true}
              options={OrganDonarList}
              setValue={(value) => {
                updateData("organDonation", value == `Yes`);
              }}
            />
          </div>
        )}
        {selectedIndex == 2 && (
          <div className="sectionContainer">
            <div className="submitButtonWrapper">
              {" "}
              <span className="sectionTitle">{postalAddress}</span>{" "}
              <div className="bottomButtonContainer">
                <button
                  disabled={isAddressValid}
                  className={
                    isAddressValid ? "disableSubmitButton" : "addItemButton"
                  }
                  onClick={() => savePostalAddress()}
                >
                  {loading ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    <span>{`SUBMIT`}</span>
                  )}
                </button>
              </div>{" "}
            </div>
            <div className="profileSectionDivider" />
            <ProfileRowSection
              setIsValid={setIsAddressValid}
              label={`Address`}
              value={userData.postalAddress[0].info}
              fieldType="address"
              onPlaceSelected={(data) => {
                let tempData = userData.postalAddress[0];

                data.address_components.map((dataItem) => {
                  dataItem.types.map((type) => {
                    if (type === fieldTypeEnums.locality) {
                      tempData.city = dataItem.long_name;
                    } else if (type === fieldTypeEnums.admin) {
                      tempData.state = dataItem.long_name;
                    } else if (type === fieldTypeEnums.country) {
                      tempData.country = dataItem.long_name;
                    } else if (type === fieldTypeEnums.code) {
                      tempData.postalcode = dataItem.long_name;
                      tempData.zipCode = dataItem.long_name;
                    }
                  });
                });
                updateData("postalAddress", [tempData]);
              }}
              setValue={(value) => {
                let tempData = userData.postalAddress[0];
                tempData.info = value;
                updateData("postalAddress", [tempData]);
              }}
            />
            <ProfileRowSection
              setIsValid={setIsAddressValid}
              label={`Postal Code`}
              isRequired={true}
              value={
                userData.postalAddress[0].postalcode ||
                userData.postalAddress[0].zipCode
              }
              fieldType="input"
              setValue={(value) => {
                let tempData = userData.postalAddress[0];
                tempData.postalcode = value;
                tempData.zipCode = value;
                updateData("postalAddress", [tempData]);
              }}
            />
            <ProfileRowSection
              setIsValid={setIsAddressValid}
              label={`City`}
              isRequired={true}
              value={userData.postalAddress[0].city}
              fieldType="input"
              setValue={(value) => {
                let tempData = userData.postalAddress[0];
                tempData.city = value;
                updateData("postalAddress", [tempData]);
              }}
            />
            {/* <ProfileRowSection
              setIsValid={setIsAddressValid}
              label={`State`}
              value={userData.postalAddress[0].state || ""}
              fieldType="input"
              isRequired={true}
              setValue={(value) => {
                let tempData = userData.postalAddress[0];
                tempData.state = value;
                updateData("postalAddress", [tempData]);
              }}
            /> */}
            <ProfileRowSection
              setIsValid={setIsAddressValid}
              label={`Country`}
              value={
                userData.postalAddress[0].country
                  ? userData.postalAddress[0].country
                  : countriesList[0].value
              }
              fieldType="select"
              options={countriesList}
              isRequired={true}
              setValue={(value) => {
                let tempData = userData.postalAddress[0];
                tempData.country = value;
                updateData("postalAddress", [tempData]);
              }}
            />
          </div>
        )}
        {selectedIndex == 3 && (
          <div className="sectionContainer">
            <div className="submitButtonWrapper">
              <span className="sectionTitle">{insurance}</span>
              <div className="bottomButtonContainer">
                <button
                  disabled={isInsurancelValid}
                  className={
                    isInsurancelValid ? "disableSubmitButton" : "addItemButton"
                  }
                  onClick={() => saveInsurance()}
                >
                  {loading ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    <span>{`SUBMIT`}</span>
                  )}
                </button>
              </div>
            </div>

            <div className="profileSectionDivider" />
            <div>
              {insuranceData.length > 0 &&
                insuranceData.map((item, index) => {
                  return (
                    <>
                      {insuranceData.length > 1 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: 10,
                            marginBottom: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                        >
                          <span style={{ fontWeight: "bolder" }}>{`Insurance ${
                            index + 1
                          }`}</span>
                          {index != 0 && (
                            <div
                              style={{
                                cursor: "pointer",
                                height: "20px",
                                width: "20px",
                                borderRadius: "10px",
                                background: "red",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                const tempArray = [...insuranceData];
                                tempArray.splice(index, 1);
                                setInsuranceData(tempArray);
                              }}
                            >
                              <span
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  fontSize: 12,
                                }}
                              >
                                X
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      <ProfileRowSection
                        setIsValid={setIsInsurancelValid}
                        label={`Type of Insurance`}
                        value={
                          item.type ? item.type : InsuranceTypesList[0].value
                        }
                        isRequired={true}
                        options={InsuranceTypesList}
                        fieldType="select"
                        setValue={(value) => {
                          const tempData = [...insuranceData];
                          tempData[index].type = value;
                          setInsuranceData(tempData);
                        }}
                      />
                      <ProfileRowSection
                        setIsValid={setIsInsurancelValid}
                        label={`Insurance Company`}
                        value={item.company}
                        isRequired={true}
                        fieldType="input"
                        setValue={(value) => {
                          const tempData = [...insuranceData];
                          tempData[index].company = value;
                          setInsuranceData(tempData);
                        }}
                      />
                      <ProfileRowSection
                        setIsValid={setIsInsurancelValid}
                        label={`Policy No`}
                        value={item.policy}
                        isRequired={true}
                        fieldType="input"
                        setValue={(value) => {
                          const tempData = [...insuranceData];
                          tempData[index].policy = value;
                          setInsuranceData(tempData);
                        }}
                      />
                      <ProfileRowSection
                        setIsValid={setIsInsurancelValid}
                        label={`Contact`}
                        value={item.phone}
                        isRequired={true}
                        fieldType="phone-num"
                        isCellular={false}
                        setValue={(value) => {
                          const tempData = [...insuranceData];
                          tempData[index].phone = value;
                          setInsuranceData(tempData);
                        }}
                      />
                    </>
                  );
                })}
            </div>
            {insuranceData.length < 3 && (
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  padding: "10px",
                }}
              >
                <span
                  style={{
                    color: "#47B6F6",
                    cursor: "pointer",
                    border: "0.5px solid grey",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    const tempData = [...insuranceData];
                    tempData.push({
                      type: ``,
                      company: ``,
                      policy: ``,
                      phone: `+123456789`,
                    });
                    setInsuranceData(tempData);
                  }}
                >{`ADD INSURANCE`}</span>
              </div>
            )}
          </div>
        )}

        {selectedIndex == 4 && (
          <div className="sectionContainer">
            <div className="submitButtonWrapper">
              <span className="sectionTitle">{emergency} contact(s)</span>
              <div className="bottomButtonContainer">
                <button
                  disabled={isEmergencyValid}
                  className={
                    isEmergencyValid ? "disableSubmitButton" : "addItemButton"
                  }
                  onClick={() => saveEmergencyContact()}
                >
                  {loading ? (
                    <CircularProgress size={18} color={"inherit"} />
                  ) : (
                    <span>{`SUBMIT`}</span>
                  )}
                </button>
              </div>
            </div>

            <div className="profileSectionDivider" />
            <>
              {emergencyData.length > 0 &&
                emergencyData.map((item, index) => {
                  return (
                    <>
                      {emergencyData.length > 1 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: 10,
                            marginBottom: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                          }}
                        >
                          <span style={{ fontWeight: "bolder" }}>{`Emergency ${
                            index + 1
                          }`}</span>
                          {index != 0 && (
                            <div
                              style={{
                                cursor: "pointer",
                                height: "20px",
                                width: "20px",
                                borderRadius: "10px",
                                background: "red",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                const tempArray = [...emergencyData];
                                tempArray.splice(index, 1);
                                setEmergencyData(tempArray);
                              }}
                            >
                              <span
                                style={{
                                  color: "white",
                                  fontWeight: "bold",
                                  fontSize: 12,
                                }}
                              >
                                X
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                      <ProfileRowSection
                        setIsValid={setIsEmergencyValid}
                        label={`Name`}
                        value={item.name}
                        isRequired={true}
                        fieldType="input"
                        setValue={(value) => {
                          const tempData = [...emergencyData];
                          tempData[index].name = value;
                          setEmergencyData(tempData);
                        }}
                      />
                      <ProfileRowSection
                        setIsValid={setIsEmergencyValid}
                        label={`Contact`}
                        value={item.phone}
                        isRequired={true}
                        fieldType="phone-num"
                        isCellular={true}
                        setValue={(value) => {
                          const tempData = [...emergencyData];
                          tempData[index].phone = value;
                          setEmergencyData(tempData);
                        }}
                      />
                      {index == 0 &&
                        console.log(
                          "Item Relationship : ",
                          index,
                          "-",
                          item.relationship
                        )}
                      <ProfileRowSection
                        setIsValid={setIsEmergencyValid}
                        label={`Relationship`}
                        isRequired={true}
                        value={
                          emergencyRelationshipList.some(
                            (item) =>
                              item.value == emergencyData[index].relationship ||
                              ""
                          )
                            ? item.relationship
                            : emergencyRelationshipList[
                                emergencyRelationshipList.length - 1
                              ].value
                        }
                        options={emergencyRelationshipList}
                        fieldType="select"
                        setValue={(value) => {
                          const tempData = [...emergencyData];
                          tempData[index].relationship = value;
                          setEmergencyData(tempData);
                        }}
                      />
                    </>
                  );
                })}
            </>
            {emergencyData.length < 3 && (
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  padding: "10px",
                }}
              >
                <span
                  style={{
                    color: "#47B6F6",
                    cursor: "pointer",
                    border: "0.5px solid grey",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    borderRadius: "15px",
                  }}
                  onClick={() => {
                    const tempData = [...emergencyData];
                    tempData.push({
                      name: ``,
                      phone: `+123456789`,
                      relationship: ``,
                    });
                    setEmergencyData(tempData);
                  }}
                >{`ADD EMERGENCY`}</span>
              </div>
            )}
          </div>
        )}
      </div>
      <GenericModal
        show={showModal}
        type={apiResponse.type}
        title={apiResponse.title}
        body={apiResponse.body}
        buttonText={constants.ok}
        handleClose={() => {
          setShowModal(false);
        }}
      />
      <GenericModal
        show={showDeleteModal}
        type={apiDeleteResponse.type}
        title={apiDeleteResponse.title}
        body={apiDeleteResponse.body}
        buttonText={constants.confirm}
        button2={true}
        btnLoader={loading}
        buttonText2="Close"
        primaryBtnPress={() => {
          onConfirmDelete();
        }}
        secondaryBtnPress={() => {
          setShowDeleteModal(false);
        }}
      />
      <GenericModal
        show={isDataSaveModalOpen}
        type={5}
        title={constants.rememberToSavePersonalInformation}
        buttonText={constants.ok}
        handleClose={() => {
          setDataSaveModalOpen(false);
        }}
      />
    </div>
  );
};

export default ManageAccount;
